var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pb-8" },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-shield-key")]),
          _vm._v(" Permissões "),
        ],
        1
      ),
      _c(
        "v-card-text",
        _vm._l(_vm.roles, function (item) {
          return _c("v-switch", {
            key: item,
            staticClass: "switch-tfa",
            attrs: { id: item, value: item, "hide-details": "", inset: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("span", { attrs: { id: item } }, [
                        _vm._v(" " + _vm._s(_vm._f("userRole")(item)) + " "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.form.authorities,
              callback: function ($$v) {
                _vm.$set(_vm.form, "authorities", $$v)
              },
              expression: "form.authorities",
            },
          })
        }),
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                loading: _vm.loadingSave,
                disabled: _vm.loadingSave,
              },
              on: { click: _vm.save },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }