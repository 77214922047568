<template>
  <v-card>
    <v-card-title class="pb-8">
      <v-icon left>mdi-shield-key</v-icon>
      Permissões
    </v-card-title>
    <v-card-text>
      <v-switch
        v-for="item in roles"
        :id="item"
        :key="item"
        v-model="form.authorities"
        :value="item"
        hide-details
        inset
        class="switch-tfa"
      >
        <template v-slot:label>
          <span :id="item">
            {{ item | userRole }}
          </span>
        </template>
      </v-switch>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { eventBus } from '../../../../main.js';

export default {
  props: ['id'],

  data() {
    return {
      form: {
        authorities: [],
      },

      loadingSave: false,
    };
  },

  computed: {
    ...mapState('cadastrosGerenciais/usuario', {
      user: (state) => state.user,
      roles: (state) => state.roles,
      loading: (state) => state.loading,
    }),
    ...mapGetters({ usuario: 'user/userInfo' }),
  },

  watch: {
    loading: {
      handler() {
        this.init();
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions('cadastrosGerenciais/usuario', ['saveRoles', 'saveRolesForAuth']),

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    init() {
      if (this.isInit || !this.user.id) return;
      this.form = {
        authorities: this.user.authorities,
      };

      this.isInit = true;
    },

    async save() {
      this.loadingSave = true;
      if (this.usuario.id === this.user.id) {
        try {
          await this.saveRolesForAuth(this.form);
          this.showToast('Salvo com sucesso!', 'success', 78);
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          await this.saveRoles(this.form);
          this.showToast('Alterações realizadas com sucesso!', 'success', 78);
        } catch (e) {
          console.log(e);
        }
      }
      this.loadingSave = false;
    },
  },
};
</script>
<style scoped>

</style>
